.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: var(--background-color);
}

.holdscreenMedia {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.status_message {
  z-index: 1;
  color: var(--color);
  font-size: 20px;
  margin: auto;
}
.status_message__top {
  margin-top: 15px;
}

.credit {
  display: inline-block;
  padding: 15px;
  color: var(--color);
  font-size: 14px;
  opacity: 0.75;
}
.credit:hover {
  opacity: 1;
}
.credit a {
  color: var(--color);
}

.spinner {
  background-color: transparent;
  margin: 15px;
}

#frame {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1024px;
  height: 768px;
  border: none;
  transform-origin: 0 0;
  background-color: black;
}

@media (min-width: 1024px) {
  #frame {
    width: 100%;
  }
}

@media (min-width: 1366px) {
  #frame {
    width: 1366px;
  }
}
